<template>
  <AppLayout>
    <main>
      <div
        class="
          page-header
          pb-10
          page-header-dark
          bg-gradient-primary-to-secondary
        "
      >
        <div class="container-fluid">
          <div class="page-header-content">
            <h1 class="page-header-title">
              <div class="page-header-icon"><i data-feather="filter"></i></div>
              <span>User</span>
            </h1>
            <div class="page-header-subtitle ml-2">Update User Details</div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-n10">
        <div class="card mb-4">
          <div class="card-header">User</div>
          <div class="card-body">
            <div v-if="pageLoading">
              <img
                style="width: 30%"
                class="d-block mx-auto"
                src="@/assets/loader.gif"
              />
            </div>
            <div v-else class="form">
              <form @submit.prevent="handleSubmit()">
                <div class="form-group">
                  <label for="role">Select Role</label
                  ><select v-model="user.role" class="form-control" id="role">
                    <option value="">Select Role</option>
                    <option value="Admin">Admin</option>
                    <option value="Broker">Broker</option>
                    <!-- <option value="Client">Client</option> -->
                  </select>
                </div>

                <div>
                  <div class="form-group">
                    <label for="name">Full Name</label
                    ><input
                      class="form-control"
                      id="name"
                      type="text"
                      v-model="user.name"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label for="emailAddress">Email address</label
                    ><input
                      class="form-control"
                      id="emailAddress"
                      type="email"
                      v-model="user.email"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label for="number">Phone Number</label
                    ><input
                      class="form-control"
                      id="number"
                      type="text"
                      v-model="user.phoneNumber"
                      required
                    />
                  </div>
                </div>

                <div v-if="user.role == 'Broker'" class="form-group">
                  <label for="">Marketing Staff</label>
                  <v-select
                    v-model="user.mktStaff"
                    class="form-select"
                    multiple
                    label="name"
                    :options="marketers"
                  >
                    <template #search="{ attributes, events }">
                      <!-- <span class="mt-1 mx-3 py-1" v-if="marketeer === null">
                        {{ user.mktStaff }}
                      </span> -->
                      <input
                        class="vs__search"
                        :required="
                          data.mktStaff !== 'Select Marketer' ? false : true
                        "
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                </div>

                <button v-if="loading" class="btn btn-primary disabled">
                  <div
                    class="spinner-border spinner-border-sm mx-1 text-light"
                    role="status"
                  ></div>
                  Loading...
                </button>
                <button v-else type="submit" class="btn btn-primary">
                  Update {{ user.role }} Details
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<style scoped>
.vs__dropdown-menu{
  z-index: 100 !important;
}
</style>


<script>
import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useAdminStore } from "@/stores/admin";
import { useMarineStore } from "@/stores/marine";

export default {
  name: "Update User",
  components: { AppLayout },
  data() {
    return {
      marketeer: null,
      data: {},
    };
  },
  computed: {
    ...mapState(useAdminStore, ["loading", "pageLoading", "user"]),
    ...mapState(useMarineStore, ["marketers"]),
  },

  methods: {
    ...mapActions(useAdminStore, ["updateUser", "getUser"]),
    ...mapActions(useMarineStore, ["getMarketers"]),
    async handleSubmit() {
      const res = await this.updateUser(this.user);
      if (res == "success") {
        this.$router.push("/users");
      }
    },
  },

  async created() {
    await this.getUser(this.$route.params.id);
    //this.data.mktStaff = this.user.mktStaff;
    this.getMarketers();
  },
};
</script>